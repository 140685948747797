.purchaseBtn{
    padding: 15px 25px;
    border-radius: 20px;
    background-color: #e68a00;
    border: 2px solid #e68a00;
    font-size: 16px;
    margin-right: 5px;
    color: #f4f4f4;
    cursor: pointer;
    font-family: 'Alef', sans-serif;
}

.purchaseBtn:hover{
    color: #e68a00;
    background-color: #1a1a1a;
    -webkit-transition: background-color 120ms linear;
    -ms-transition: background-color 120ms linear;
    transition: background-color 120ms linear;
}

.upgradeBtn{
    padding: 15px 25px;
    border-radius: 20px;
    background-color: #ff4d4d;
    border: 2px solid #ff4d4d;
    font-size: 16px;
    margin-left: 5px;
    color: #f4f4f4;
    cursor: pointer;
    font-family: 'Alef', sans-serif;
}

.upgradeBtn:hover{
    color: #ff4d4d;
    background-color: #1a1a1a;
    -webkit-transition: background-color 120ms linear;
    -ms-transition: background-color 120ms linear;
    transition: background-color 120ms linear;
}

.supportBtn{
    padding: 15px 25px;
    border-radius: 20px;
    background-color: #3684dd;
    border: 2px solid #3684dd;
    font-size: 16px;
    margin-left: 5px;
    color: #f4f4f4;
    cursor: pointer;
    font-family: 'Alef', sans-serif;
}

.supportBtn:hover{
    color: #3684dd;
    background-color: #1a1a1a;
    -webkit-transition: background-color 120ms linear;
    -ms-transition: background-color 120ms linear;
    transition: background-color 120ms linear;
}