.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Alef', sans-serif;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 140px;
    margin-bottom: 10px;
}

.button{ 
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Alef', sans-serif;
    cursor: pointer; 
    font-size: 17px; 
    padding: 22px 40px; 
    border: 1px solid black; 
    border-radius: 30px; 
    color: white; 
    background-color: rgb(156, 39, 176); 
    margin: 70px auto; 
}

.button:hover{
    background-color: rgb(117, 29, 133);
}